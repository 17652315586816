import React from 'react'
import Layout from '../layouts'
import OnSite from '../images/On-site-sales.jpg'
import Collapsible from 'react-collapsible'

const SalesGrowthAssessment = () => (
	<Layout head={{ title: 'Sales Growth Assessment and Actionable Insights' }}>
		<div className="gsp">
			<div
				id="hero-section"
				className="bg-cover"
				style={{ background: `url("${OnSite}")` }}
			>
				<div className="container">
					<div className="slim-content">
						<h1 className="text-red">
							Sales Growth Assessment and Actionable Insights
						</h1>
						<p className="py-4">
							Do you know why your sales are stagnant or declining?
							<br />
							Do you have clear insights and a plan to rapidly grow and scale
							sales?
							<br />
							We do!
						</p>
					</div>
				</div>
			</div>
			<div className="container py-5">
				<p>
					ProGrowth Advisors leverages the Sales Xceleration platform to address
					the critical core areas to improve declining or stagnant sales and set
					your business on a course to success. Through our in-depth assessment
					and analyses, we provide a clear set of actionable insights and
					strategies to execute that lead to measurable results.
				</p>
				<p>
					If you’re struggling to achieve the growth you’d planned for your
					business, we can provide very clear direction to the highest value
					areas to focus on to rapidly grow your business.
				</p>

				<h3>
					We use a 3-step proven process to identify areas in need of
					improvement and set priorities that drive results:
				</h3>

				<div className="bg-grey my-4">
					<Collapsible trigger="Complimentary Initial Discovery:" open>
						<p className="pt-3">
							We will meet with you and your team for a 1-2 hour complimentary
							discovery and assessment session, providing some insights as to
							potential areas of positive impact and priorities.
						</p>
					</Collapsible>
					<Collapsible trigger="In-depth Investigation and Analysis:">
						<p className="pt-3">
							We review the major growth impact areas and provide a forensic
							analysis of the health of each of those areas. Areas may include:
						</p>
						<ul className="circle-chevron">
							<li>Growth Culture Insights</li>
							<li>Product Validation</li>
							<li>Competitive Analysis</li>
							<li>Go to Market Strategy</li>
							<li>Lead Generation, Strategy and Systems</li>
							<li>Sales Process</li>
							<li>Sales & Marketing Tools Evaluation and Optimization</li>
							<li>
								Organization Effectiveness – Staff Assessment, Organization
								Structure, Compensation, Hiring Practices, Training, Onboarding
							</li>
							<li>
								Sales Financial and Analytics Systems – Forecasting, Pipeline
								accuracy, KPI’s
							</li>
						</ul>
					</Collapsible>
					<Collapsible trigger="Actionable Insight Plan Presentation and Collaboration:">
						<p className="pt-3">
							We present our findings and collaborate with you and your team to
							develop a prioritized action plan that improves results in 30-90
							days. Most importantly, we cultivate a plan to sustain sales
							growth in the future.
						</p>
					</Collapsible>
				</div>

				<p>
					Schedule your Free Initial Discovery Consultation now by calling{' '}
					<a href="tel:512-696-1333">512-696-1333</a> or email{' '}
					<a href="mailto:Joe@ProGrowthAdvisors.com.com">
						Joe@ProGrowthAdvisors.com
					</a>
				</p>
			</div>
		</div>
	</Layout>
)

export default SalesGrowthAssessment
